/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Observable } from 'rxjs';
import { TbGrupoCatalogoOperativo } from '~models/maestros';
import { CustomHttpClientService } from '~shared/services/custom-http-client.service';

const url = `${environment.HOST_MAESTROS_SYNC}/tbgrupocatalogooperativos`;

@Injectable({
	providedIn: 'root',
})
export class TbGrupoCatalogoOperativoService {
	private _dataSelect$?: Observable<any[]>;
	constructor(protected http: CustomHttpClientService) {}

	getCodigoLikeAndIdSistema(codigo: string, idSistema: number): Observable<TbGrupoCatalogoOperativo> {
		return this.http.get<ApiResponse<TbGrupoCatalogoOperativo>>(`${url}/findByCodigoLikeAndIdSistema/${codigo}/${idSistema}`).pipe(map((res) => res.data));
	}
}

import { catchError } from 'rxjs/operators';
import { MessageUtilService } from './message-util.service';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { isUndefinedOrNull } from '~shared/utils/Utils';
import { ApiResponse, CookiesService } from 'sumax-erp-schematics';
import { Logout } from '@core/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';

@Injectable({
	providedIn: 'root',
})
export class CustomHttpClientService {
	constructor(private http: HttpClient, private messageUtilService: MessageUtilService, private cookiesService: CookiesService, private _store: Store<AppState>) {}

	getBearerAuthorization(api: string) {
		const valueToken = api.indexOf('sumax-erp-backend-maestros-sync') === -1 ? environment.idToken : environment.maestroSyncToken;
		return 'Bearer ' + this.cookiesService.getItem(valueToken);
	}

	get<T>(url: string) {
		return this.http.get<T>(url, { headers: { Authorization: this.getBearerAuthorization(url) } }).pipe(
			catchError((err: HttpErrorResponse | any) => {
				console.log(err);
				return this.throwError(err);
			})
		);
	}

	post<T>(
		url: string,
		data: any,
		options?: {
			headers?: HttpHeaders | { [header: string]: string | string[] };
			observe?: 'body';
			params?: HttpParams | { [param: string]: string | string[] };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	) {
		return this.http.post<T>(url, data, { headers: { Authorization: this.getBearerAuthorization(url) } }).pipe(
			catchError((err: HttpErrorResponse | any) => {
				console.log(err);
				return this.throwError(err);
			})
		);
	}

	put<T>(
		url: string,
		data: any,
		options?: {
			headers?: HttpHeaders | { [header: string]: string | string[] };
			observe?: 'body';
			params?: HttpParams | { [param: string]: string | string[] };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	) {
		return this.http.put<T>(url, data, { headers: { Authorization: this.getBearerAuthorization(url) } }).pipe(
			catchError((err: HttpErrorResponse | any) => {
				console.log(err);
				return this.throwError(err);
			})
		);
	}

	delete<T>(url: string) {
		return this.http.delete<T>(url).pipe(
			catchError((err: HttpErrorResponse | any) => {
				console.log(err);
				return this.throwError(err);
			})
		);
	}

	deleteWithBody<T>(url: string, body: any) {
		return this.http.request<T>('delete', url, { body, headers: { Authorization: this.getBearerAuthorization(url) } }).pipe(
			catchError((err: HttpErrorResponse | any) => {
				console.log(err);
				return this.throwError(err);
			})
		);
	}

	private throwError(err: HttpErrorResponse) {
		if (!isUndefinedOrNull(err.status)) {
			this.errStatusValidate(err);
		}
		return throwError(() => err);
	}
	private errStatusValidate(err: HttpErrorResponse) {
		if (err.status === 403) {
			this.errStatus403(err);
		} else if (err.status === 500) {
			this.internalServerError(err);
		} else if (err.status === 0 && err.statusText === 'Unknown Error') {
			this.messageUtilService.getMessageError('A999', `El servidor de busqueda no responde<br>${err.url}`);
		} else {
			this.messageUtilService.getMessageError('A999', err.message);
		}
	}
	private errStatus403(err: HttpErrorResponse) {
		if (typeof err.error !== 'undefined' && (err.error.codigo === '000046' || err.error.codigo === 'GEN000046')) {
			const errorResponse: ApiResponse = err.error;
			this.messageUtilService.getMessageError(errorResponse.codigo.toString(), errorResponse.titulo, errorResponse.mensaje);
		} else {
			if (err.message) {
				this.messageUtilService.getMessageError('A999', err.message);
			} else {
				this.messageUtilService.getMessageError('A999', 'Acceso Denegado');
			}
		}
	}
	private internalServerError(err: HttpErrorResponse) {
		if (!isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'A' && err.error.mensaje) {
			this.messageUtilService.getAlertWarning(err.error.mensaje);
		} else if (!isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'E' && err.error.mensaje) {
			this.messageUtilService.getMessageError('A999', err.error.mensaje);
			if (err.error.codigo === 'SRY000001' || err.error.codigo === 'GEN000046' || err.error.codigo === '000046') {
				// Usuario no autorizado
				this._store.dispatch(new Logout());
			}
		} else if (isUndefinedOrNull(err.error) || err.status.toString() === err.error.codigo) {
			this.messageUtilService.getMessageError('A999', err.error && err.error.type && err.error.type != 'error' && err.error.mensaje ? err.error.mensaje : err.message);
		} else if (!isUndefinedOrNull(err.error) && !err.ok) {
			this.messageUtilService.getMessageError('A999', err.message);
		}
	}
}

import { Injectable } from '@angular/core';
import toastr from 'toastr';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root',
})
export class MessageUtilService {
	private _toastrOptions: ToastrOptions;

	constructor() {
		this._toastrOptions = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
	}

	message = '';

	getMessageError(codigo: string, element = '', message?: string) {
		switch (codigo) {
			case 'A001':
				this.message = `El campo ${element}  es requerido`;
				break;
			case 'A002':
				this.message = `El campo ${element} no cumple con el formato establecido`;
				break;
			case 'A003':
				this.message = `La fecha ingresada no puede ser superior a la fecha del dí­a actual`;
				break;
			case 'A004':
				this.message = `La fecha del documento no puede ser menor a la fecha del ultimo documento ingresado`;
				break;
			case 'GEN000046':
				this.message = `${message}`;
				break;
			case '000046':
				this.message = `${message}`;
				break;
			case 'A005':
				this.message = `No ha ingresado la serie`;
				break;
			case 'A999':
				this.message = `${element}`;
				element = 'Error';
				break;
			case '500':
				this.message = `Por favor comuniquese con el soporte del Sistema`;
				break;
			default:
				this.message = message!;
				break;
		}
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['error'](this.message, `<h5 class='text-white'> ${element} </h5>`);
		return toastr;
	}

	getAlertSucces(mensaje: string, title: string = 'Éxito') {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['success'](mensaje, `<h5 class='text-white'> ${title} </h5>`);
		return toastr;
	}

	getAlertWarning(mensaje: string, title: string = 'Error') {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['warning'](mensaje, `<h5 class='text-white'> ${title} </h5>`);
		return toastr;
	}

	getAlertWarningBasic(mensaje: string) {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['warning'](mensaje);
		return toastr;
	}

	getMessageInfoAction(mensaje: string) {
		return this.messageInfo(mensaje);
	}

	getMessageWarning(mensaje: string) {
		const message = `Esta acción no se puede deshacer`;
		const SwalMixin = Swal.mixin({
			focusCancel: true,
		});
		return SwalMixin.fire({
			title: mensaje,
			icon: 'question',
			text: message,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			confirmButtonColor: '#0ABB87',
			cancelButtonColor: '#FD397A',
		});
	}

	getMessageQuestion(titulo: string, mensaje?: string) {
		const SwalMixin = Swal.mixin({
			focusCancel: true,
		});
		return SwalMixin.fire({
			title: titulo,
			icon: 'question',
			text: mensaje,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			confirmButtonColor: '#0ABB87',
			cancelButtonColor: '#FD397A',
		});
	}

	getMessageSuccess(titulo: string, mensaje: string) {
		return Swal.fire({
			icon: 'success',
			title: titulo,
			text: mensaje,
		});
	}

	getFailMessage(mensaje: string) {
		return Swal.fire({
			icon: 'error',
			title: 'Error',
			text: mensaje,
		});
	}

	getMessageSuccessDelete(mensaje: string) {
		return Swal.fire({
			icon: 'success',
			title: 'Eliminación exitosa',
			text: mensaje,
		});
	}

	getMessageInfo(mensaje: string) {
		return Swal.fire({
			icon: 'info',
			title: 'Información',
			text: mensaje,
		});
	}

	getMessageSuccessTransmit(mensaje: string) {
		return Swal.fire({
			icon: 'success',
			title: 'Transmisión exitosa',
			text: mensaje,
		});
	}

	getMessageInfoSmall(mensaje: string, title = 'Información') {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['info'](mensaje, `<h5 class='text-white'> ${title} </h5>`);
		return toastr;
	}

	getImgBasic(titulo: string, imgUrl: string) {
		return Swal.fire({
			title: titulo,
			imageUrl: imgUrl,
			imageAlt: 'Custom image',
		});
	}

	getAlertSuccessBasic(mensaje: string) {
		return this.messageInfo(mensaje);
	}

	getMessageLoading(title: string, mensaje = '') {
		Swal.fire({
			title: `${title}`,
			text: mensaje,
			allowOutsideClick: false,
		});
		return Swal.showLoading();
	}

	closeLoading() {
		return Swal.close();
	}

	messageInfo(mensaje: string) {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['success'](mensaje);
	}

	getMessageSuccessSmall(mensaje: string, title = 'Información') {
		toastr.options = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
		toastr['success'](mensaje, `<h5 class='text-white'> ${title} </h5>`);
		return toastr;
	}

	success(message: string, title?: string): JQuery<HTMLElement> {
		return toastr.success(message, title ? `<h5 class='text-white'> ${title} </h5>` : undefined, this._toastrOptions);
	}
}

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Observable } from 'rxjs';
import { TbCatalogoOperativo } from '~models/maestros';
import { CustomHttpClientService } from '~shared/services/custom-http-client.service';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

const url = `${environment.HOST_MAESTROS_SYNC}/tbcatalogooperativos`;
@Injectable({
	providedIn: 'root',
})
export class TbCatalogoOperativoService {
	constructor(protected http: CustomHttpClientService) {}

	findAllByCodigoLike(codigo: string): Observable<NgSelectOption<TbCatalogoOperativo>[]> {
		return this.http.get<ApiResponse<TbCatalogoOperativo>>(`${url}/findAllByCodigoLike/${codigo}`).pipe(
			map((res) => res.data as TbCatalogoOperativo[]),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			mergeMap((data) =>
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				data.map((res: any) => {
					return {
						...res,
						value: res.idCatalogoOperativo,
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),

			toArray()
		);
	}
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CustomHttpClientService } from 'sumax-erp-schematics';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TbMoneda } from '~models/maestros-sync/maestros';
import { SearchTbMonedaDto } from '~models/maestros-sync/maestros/custom/SearchTbMonedaDto';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

const url = `${environment.HOST_MAESTROS_SYNC}/tbmonedas`;

@Injectable({
	providedIn: 'root',
})
export class TbMonedaService {
	private _dataSelect$?: Observable<any[]>;

	constructor(protected http: CustomHttpClientService) {}

	findAllByEstadoNgSelect(estado: boolean): Observable<TbMoneda[]> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return this.http.get<ApiResponse>(`${url}/findAllByEstadoNgSelect/${estado}`).pipe(map((res) => res.data as TbMoneda[]));
	}

	getNgSelectSimple(saveInMemory = true): Observable<any[]> {
		if (!saveInMemory) {
			this._dataSelect$ = undefined;
		}
		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllByEstadoNgSelect(true).pipe(
				mergeMap((data: TbMoneda[]) => {
					return data.map((res) => {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						return { label: `${res.codigo} - ${res.nombre}`, value: res.idMoneda };
					});
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelect$;
	}

	findAllSistemaPaisEstadoNgSelect(searchTbMonedaDto: SearchTbMonedaDto): Observable<TbMoneda[]> {
		return this.http.post<ApiResponse<TbMoneda[]>>(`${url}/findAllSistemaPaisEstadoNgSelect`, searchTbMonedaDto).pipe(map((res) => res.data));
	}

	getSelectListTipoMonedaPais(isInternacional = true): Observable<NgSelectOption<TbMoneda>[]> {
		const searchTbMonedaDto: SearchTbMonedaDto = { codigoPaisNacional: '', codigoSciSistema: '' };
		if (isInternacional) {
			searchTbMonedaDto.codigoPaisNacional = 'PE';
		}
		searchTbMonedaDto.estado = true;

		return this.findAllSistemaPaisEstadoNgSelect(searchTbMonedaDto).pipe(
			map((data) =>
				data.map((item) => ({
					...item,
					value: `${item.idMoneda}`,
					label: `${item.codigo} - ${item.nombre}`,
				}))
			)
		);
	}

	getSelect(item: TbMoneda | null | undefined): NgSelectOption<TbMoneda> | null {
		return item
			? {
					...item,
					value: item.idMoneda,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}

	findAllByIdList(ids: number[]): Observable<TbMoneda[]> {
		return this.http.post<ApiResponse<TbMoneda[]>>(`${url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}

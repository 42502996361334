import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { ApiResponse } from 'sumax-erp-schematics';
import { TbTipoObservacion } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbTipoObservacionService {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbtipoobservaciones`;
	constructor(private readonly _http: HttpClient) {}

	findAllByIdSistemaListAndEstado(request: { idSistema: number[]; estado?: boolean }): Observable<NgSelectOption<TbTipoObservacion>[]> {
		request.estado = true;
		return this._http.post<ApiResponse<TbTipoObservacion[]>>(`${this._url}/findAllByIdSistemaListAndEstado`, request).pipe(
			map((resp) => resp.data),
			map((data) =>
				data.map((item) => ({
					...item,
					value: item.idTipoObservacion,
					label: `${item.codigo} - ${item.nombre}`,
				}))
			)
		);
	}

	getSelect(item: TbTipoObservacion | null | undefined): NgSelectOption<TbTipoObservacion> | null {
		return item
			? {
					...item,
					value: item.idTipoObservacion,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';
import { TbTipoImpuesto } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class TbTipoImpuestoService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbtipoimpuestos`;

	constructor(protected readonly _http: HttpClient) {}

	findTipoImpuesto(): Observable<TbTipoImpuesto> {
		return this._http.get<ApiResponse<TbTipoImpuesto>>(`${this._url}/findByPredeterminadoDefault`).pipe(map((res) => res.data));
	}
}

import Swal from 'sweetalert2';

export function showLoading(title = 'Cargando...'): void {
	void Swal.fire({
		title: `${title}`,
		html: `<div><img style="width: 90px; height: auto;" src="assets/img/factura/loading_acuerdo_comercial.gif"><div>`,
		timerProgressBar: true,
		allowOutsideClick: false,
		showConfirmButton: false,
		width: '300px',
	});
}

export function showSmallLoading(title = 'Cargando...'): void {
	void Swal.fire({
		title: `${title}`,
		toast: true,
		// backdrop: true,
		timerProgressBar: true,
		allowOutsideClick: false,
		onBeforeOpen: () => {
			Swal.showLoading();
		},
	});
}

export function hideLoading(): void {
	Swal.close();
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';
import { TbImpuesto } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class TbImpuestoService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbimpuestos`;

	constructor(protected readonly _http: HttpClient) {}

	findByTipoImpuestoAndFchInicio(idTipoImpuesto: number, fechaInicio: Date): Observable<TbImpuesto[]> {
		return this._http.get<ApiResponse<TbImpuesto[]>>(`${this._url}/findByTipoImpuestoAndFchInicio/${idTipoImpuesto}/${fechaInicio}`).pipe(map((res) => res.data));
	}
}

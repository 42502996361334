import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { TbCondicionServicio } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class TbCndicionServiciosService {
	private _url = `${environment.HOST_MAESTROS}/tbcondicionservicios`;
	constructor(private readonly _http: HttpClient) {}

	findAllByIdSistemaList(ids: number[]): Observable<TbCondicionServicio[]> {
		return this._http.post<ApiResponse<TbCondicionServicio[]>>(`${this._url}/model-se-ro/findAllByIdSistemaList`, ids).pipe(map((resp) => resp.data));
	}

	findAllByIdList(ids: number[]): Observable<TbCondicionServicio[]> {
		return this._http.post<ApiResponse<TbCondicionServicio[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
